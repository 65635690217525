import { CommonErrorHandlerService } from '@app/core/services/common-error-handler.service';
import { AbstractControl, FormControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormFieldError } from '@app/core/models/form-field-error.model';
import { fromEvent, Subscription } from 'rxjs';
import { OnlineApplicationService } from '@app/core/services/online-application/online-application.service';

export class BaseFormComponent {
  public summaryErrors: string[] = [];
  public fieldErrors: { [key: string]: string[] } = {};
  public form: UntypedFormGroup;
  public readonly fieldNames: { [key: string]: string } = {};
  public companyId: string;
  public maxDefaultDate = new Date('2101-01-01Z00:00:00:000');
  public minDefaultDate = new Date('1900-01-01Z00:00:00:000');
  private listenerAdded: { [key: string]: boolean } = {};
  protected subscriptions: Subscription;

  constructor(
    protected commonErrorHandler: CommonErrorHandlerService,
    protected onlineApplicationService: OnlineApplicationService
  ) {
    this.subscriptions = new Subscription();
    this.companyId = this.onlineApplicationService.onlineApplicationConfiguration?.value?.companyId;
  }

  public getFormGroupValidationErrors() {
    this.summaryErrors = [];
    this.travelThroughFormControls(this.form, null, (key: string, field) => {
      const validationResults = this.validateField(key, field);
      this.fieldErrors[key] = validationResults.map(error => error.field);
      this.summaryErrors = this.summaryErrors.concat.apply(this.summaryErrors, validationResults.map(error => error.summary));
    });

    return this.summaryErrors;
  }

  public getFormData(): UntypedFormGroup {
    return this.form;
  }

  public datePickerBlur(controlKey: string, value) {
    const control = this.form?.get(controlKey);
    if (control && control?.value !== value) {
      control.patchValue(value);
      this.fieldErrors[controlKey] = this.validateField(controlKey, control).map(e => e.field);
      control.markAsDirty();
    }
  }

  public hasErrors(controlKey: string): boolean {
    return !!this.fieldErrors[controlKey]?.length;
  }

  public blockInvalidCharacters(event, chars = ['-', '+', 'e', '.']) {
    if (chars.indexOf(event.key) > -1) {
      event.preventDefault();
    }
  }

  public isFormValid(): boolean {
    return this.form?.valid;
  }

  protected validateField(key: string, field: AbstractControl<any, any>): FormFieldError[] {
    return this.commonErrorHandler.getErrors(field.errors, this.fieldNames, key);
  }

  protected validateControl(key: string, field: AbstractControl<any, any>) {
    this.fieldErrors[key] = this.validateField(key, field).map(error => error.field);
  }

  protected addValidationEvents() {
    this.travelThroughFormControls(this.form, null, (key: string, field) => {
      let keys = [key];
      if (!key.match(/.\d./)) {
        keys = key.split('.');
      }
      const el = document.getElementById(keys[keys?.length - 1] || key);
      if (el && el.tagName.toLowerCase() !== 'app-jjk-date-picker' && !this.listenerAdded[key]) {
        this.listenerAdded[key] = true;
        this.subscriptions.add(fromEvent(el, 'blur').subscribe(value => {
          this.fieldErrors[key] = this.validateField(key, field).map(error => error.field);
        }));
      }
    });
  }

  protected travelThroughFormControls(formGroup = this.form, parentKey: string = null, callbackFn = null) {
    let result = [];
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof UntypedFormGroup) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        result = result.concat(this.travelThroughFormControls(control, fullKey, callbackFn));
        const callbackFnResult = callbackFn?.call(this, key, control);

        if (callbackFnResult && callbackFnResult.length > 0) {
          result.push(callbackFnResult);
        }
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((form: UntypedFormGroup, index) => result = result.concat(this.travelThroughFormControls(form, `${key}.${index}`, callbackFn)));
      } else {
        const fieldErrorKey = parentKey ? `${parentKey}.${key}` : key;
        const callbackFnResult = callbackFn?.call(this, fieldErrorKey, control);

        if (callbackFnResult) {
          result.push(callbackFnResult);
        }
      }
    });

    return result;
  }

  public dateBlur(controlKey: string, value: Date | string, control: FormControl<Date | string>) {
    setTimeout(() => {
      if (control && control?.value !== value) {
        control.patchValue(value);
        control.markAsDirty();
      }
      this.validateControl(controlKey, control);
    }, 20);
  }
}
