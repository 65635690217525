import { AdditionalQuestionModel } from '@app/core/models/additional-question.model';
import { ApplicantAdditionalDriverExperienceModel } from '@app/core/models/applicant-additional-drivier-experience.model';
import { ApplicantAdditionalLicenseInformationModel } from '@app/core/models/applicant-additonal-license-information.model';
import { ApplicantAdditionalQuestionModel } from '@app/core/models/applicant-additional-question.model';
import { ApplicantDrivingInformationModel } from '@app/core/models/applicant-driving-information.model';
import { ApplicantEducationTrainingModel } from '@app/core/models/applicant-education-training.model';
import { ApplicantFormIOModel } from '@app/core/models/applicant-form-io.model';
import { ApplicantGapsInEmploymentModel } from '@app/core/models/applicant-gaps-in-employment.model';
import { ApplicantGeneralInformationModel } from '@app/core/models/applicant-general-information.model';
import { ApplicantPersonalInformationModel } from '@app/core/models/applicant-personal-information.model';
import { ApplicantPreviousAddressModel } from '@app/core/models/applicant-previous-address.model';
import { ApplicantPreviousEmployerConsortiumOrCarrierValuesModel } from '@app/core/models/applicant-previous-employer-consortium-or-carrierValues.model';
import { ApplicantPreviousEmployerModel } from '@app/core/models/applicant-previous-employer.model';
import { ApplicantsAccidentHistoryModel } from '@app/core/models/applicant-accident-history.model';
import { ApplicantViolationHistoryModel } from '@app/core/models/applicant-violation-history.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CompanyInfoModel } from '@app/core/models/company-info.model';
import { DigitalSignatureSettings } from '@app/core/models/digital-signature-settings.model';
import { EndorsementModel } from '@app/core/models/endorsement.model';
import { EquipmentClassModel } from '@app/core/models/equipment-class.model';
import { ETagResult } from '@app/core/models/e-tag-result.model';
import { FormFileName, FormFileType } from '@app/core/enums/form-file-name.enum';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LicenseclassModel } from '@app/core/models/license-class.model';
import { ListItem } from '@app/core/models/list-item.model';
import { LogoModel } from '@app/core/models/logo.model';
import { MainHeaderModel } from '@app/core/models/main-header.model';
import { OnlineApplicationTextModel } from '@app/core/models/online-application-text.model';
import { QuestionSectionType } from '@app/core/enums/question-section-types.enum';
import { FormIOExceptionMessage } from '@app/models/form-io-exception-message';

@Injectable({
  providedIn: 'root'
})
export class OnlineApplicationService {
  private readonly apiUrl = '/api/OnlineApplication';

  public readonly onlineApplicationConfiguration = new BehaviorSubject<CompanyInfoModel>(null);
  public readonly companyNumber = new BehaviorSubject<number>(null);

  private _currentApplicantETagResult: ETagResult | null = null;
  public get currentApplicantETag(): ETagResult {
    return this._currentApplicantETagResult;
  }

  constructor(private http: HttpClient) { }

  getOnlineApplicationConfiguration(companyNumber: string | number): Observable<CompanyInfoModel> {
    return this.http.get<CompanyInfoModel>(`${this.apiUrl}/GetOnlineApplicationConfiguration`, { params: { companyNumber } })
      .pipe(
        tap(information => {
          this.onlineApplicationConfiguration.next(information);
          this.companyNumber.next(+companyNumber);
        })
      );
  }

  getOnlineApplicationLogo(companyId: string): Observable<LogoModel> {
    return this.http.get<LogoModel>(`${this.apiUrl}/GetOnlineApplicationLogo`, { params: { companyId } });
  }

  getMainHeaderConfiguration(): Observable<MainHeaderModel> {
    return this.onlineApplicationConfiguration.pipe(
      switchMap(res => {
        if (res) {
          const logo = this.getOnlineApplicationLogo(res?.companyId).pipe(
            map(logoRes => `data:image/png;base64,${logoRes?.logo}`),
            catchError(() => of(null)),
          );
          return logo.pipe(map(logoRes => ({ companyInfo: res, logo: logoRes } as MainHeaderModel)));
        } else {
          return of({ companyInfo: res, logo: null } as MainHeaderModel);
        }
      }));
  }

  getOnlineApplicationText(companyId: string): Observable<OnlineApplicationTextModel[]> {
    return this.http.get<OnlineApplicationTextModel[]>(`${this.apiUrl}/GetOnlineApplicationText`, { params: { companyId } });
  }

  getAdditionalQuestions(companyId: string, section: QuestionSectionType): Observable<AdditionalQuestionModel[]> {
    return this.http.get<AdditionalQuestionModel[]>(`${this.apiUrl}/GetAdditionalQuestions`, { params: { companyId, section } });
  }

  getStates(): Observable<ListItem[]> {
    return this.http.get<ListItem[]>(`${this.apiUrl}/GetStates`);
  }

  getEndorsements(companyId: string): Observable<EndorsementModel[]> {
    return this.http.get<EndorsementModel[]>(`${this.apiUrl}/GetEndorsements`, { params: { companyId } });
  }

  getLicenseClass(companyId: string): Observable<LicenseclassModel[]> {
    return this.http.get<LicenseclassModel[]>(`${this.apiUrl}/GetLicenseClass`, { params: { companyId } });
  }

  getEducationGrades(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/GetEducationGrades`);
  }

  getEquipmentClasses(companyId: string): Observable<EquipmentClassModel[]> {
    return this.http.get<EquipmentClassModel[]>(`${this.apiUrl}/GetEquipmentClasses`, { params: { companyId } });
  }

  getCompanyDigitalSignatureSettings(companyId: string): Observable<DigitalSignatureSettings> {
    return this.http.get<DigitalSignatureSettings>(`${this.apiUrl}/GetCompanyDigitalSignatureSettings`, { params: { companyId } });
  }

  createForms(companyId: string, applicantId: string = null, formIO: ApplicantFormIOModel,  sessionId?: string, formValue?: string): Observable<FormIOExceptionMessage[]> {
    let params = new HttpParams()
      .set('companyId', companyId)
      .set('applicantId', applicantId);
      if (sessionId !== null && sessionId !== undefined) {
        params = params.set('sessionId', sessionId);
      }
      if (formValue !== null && formValue !== undefined) {
        params = params.set('formValue', formValue);
      }
    return this.http.post<FormIOExceptionMessage[]>(`${this.apiUrl}/CreateForms`, formIO, { params });
  }

  /**
   * @returns Applicant ETag
   */
  createApplicant(companyId: string, body: ApplicantPersonalInformationModel): Observable<ETagResult> {
    return this.http.post<ETagResult>(`${this.apiUrl}/CreateApplicant`, body, { params: { companyId } }).pipe(tap(res => this._currentApplicantETagResult = res));
  }

  /**
   * @returns Applicant ETag
   */
  updateGeneralInformation(companyId: string, id: string, body: ApplicantGeneralInformationModel): Observable<ETagResult> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('id', id);
    return this.http.put<ETagResult>(`${this.apiUrl}/UpdateGeneralInformation`, body, { params }).pipe(tap(res => this._currentApplicantETagResult = res));
  }

  /**
   * @returns Applicant ETag
   */
  updateDrivingInfo(companyId: string, id: string, body: ApplicantDrivingInformationModel): Observable<ETagResult> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('id', id);
    return this.http.put<ETagResult>(`${this.apiUrl}/UpdateDrivingInfo`, body, { params }).pipe(tap(res => this._currentApplicantETagResult = res));
  }

  /**
   * @returns ApplicantPreviousLicense ETag
   */
  createAdditionalLicense(companyId: string, body: ApplicantAdditionalLicenseInformationModel): Observable<ETagResult> {
    return this.http.post<ETagResult>(`${this.apiUrl}/CreateAdditionalLicense`, body, { params: { companyId } });
  }

  /**
   * @returns ApplicantDrivingExperience ETag
   */
  createAdditionalDrivingExperience(companyId: string, body: ApplicantAdditionalDriverExperienceModel): Observable<ETagResult> {
    return this.http.post<ETagResult>(`${this.apiUrl}/CreateAdditionalDrivingExperience`, body, { params: { companyId } });
  }

  /**
   * @returns Applicant ETag
   */
  updateEducationTraining(companyId: string, id: string, body: ApplicantEducationTrainingModel): Observable<ETagResult> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('id', id);
    return this.http.put<ETagResult>(`${this.apiUrl}/UpdateEducationTraining`, body, { params }).pipe(tap(res => this._currentApplicantETagResult = res));
  }

  /**
   * @returns ApplicantTrafficVioRecord ETag
   */
  createTrafficViolation(companyId: string, body: ApplicantViolationHistoryModel) {
    return this.http.post<ETagResult>(`${this.apiUrl}/CreateTrafficViolation`, body, { params: { companyId } });
  }
  
  /**
   * @returns ApplicantAccidentRecord ETag
   */
  createAccidentHistory(companyId: string, body: ApplicantsAccidentHistoryModel) {
    return this.http.post<ETagResult>(`${this.apiUrl}/CreateAccidentHistory`, body, { params: { companyId } });
  }

  uploadAttachments(companyId: string, applicantId: string, file: File, date: Date, name: string, type: string): Observable<any> {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    formData.append('companyId', companyId);
    formData.append('applicantId', applicantId);
    formData.append('name', name);
    formData.append('type', type);

    if (date) {
      formData.append('date', date.toISOString());
    }
    return this.http.post<any>(`${this.apiUrl}/UploadAttachments`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  /**
   * @returns ApplicantPreviousEmployer ETag
   */
  createPreviousEmployer(companyId: string, id: string, previousEmployer: ApplicantPreviousEmployerModel, consortiums :ApplicantPreviousEmployerConsortiumOrCarrierValuesModel[]): Observable<ETagResult> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('id', id);
    return this.http.post<ETagResult>(`${this.apiUrl}/CreatePreviousEmployer`, { previousEmployer, consortiums}, { params });
  }

  /**
   * @returns ApplicantGapsInEmployment ETag
   */
  createEmploymentGap(companyId: string, body: ApplicantGapsInEmploymentModel) {
    return this.http.post<ETagResult>(`${this.apiUrl}/CreateEmploymentGap`, body, { params: { companyId } });
  }

  /**
   * @returns ApplicantAdditionalQuestionValues ETag
   */
  additionalQuestionsResponse(companyId: string, id: string, body: ApplicantAdditionalQuestionModel[]): Observable<ETagResult> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('id', id);
    return this.http.post<ETagResult>(`${this.apiUrl}/AdditionalQuestionsResponse`, body, { params });
  }

  /**
   * @returns ApplicantAddress ETag
   */
  createPreviousAddress(companyId: any, body: ApplicantPreviousAddressModel): Observable<ETagResult> {
    return this.http.post<ETagResult>(`${this.apiUrl}/CreatePreviousAddress`, body, { params: { companyId } });
  }

  downloadAttachment(attachmentType: FormFileType, companyId: string, applicantId: string, attachmentName: FormFileName) {
    this.http.get(`${this.apiUrl}/DownloadAttachment`, {
      params: new HttpParams()
        .set('attachmentType', attachmentType)
        .set('applicantId', applicantId)
        .set('companyId', companyId),
      headers: new HttpHeaders({
        accept: '*/*'
      }), responseType: 'blob'
    }).subscribe(res => {
      this.createAndDownloadBlobFile(res, attachmentName, true);
    });
  }

  sendApplicantReceivedNotifyEmail(companyId: string, applicantId: string, emailTo: string, emailToFirstName: string, applicationDate: string, applicationTime: string): Observable<{applicantName: string}> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('applicantId', applicantId)
      .set('emailTo', emailTo)
      .set('emailToFirstName', emailToFirstName)
      .set('applicationDate', applicationDate)
      .set('applicationTime', applicationTime);
    return this.http.post<{applicantName: string}>(`${this.apiUrl}/SendApplicantReceivedNotifyEmail`, null, { params });
  }

  deleteApplicant(companyId: string, applicantId: string, eTag: string) {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('applicantId', applicantId)
      .set('eTag', eTag);
    return this.http.delete(`${this.apiUrl}/DeleteApplicant`, { params }).pipe(tap(_ => this._currentApplicantETagResult = null));
  }

  private createAndDownloadBlobFile(blob: Blob | MediaSource, originalFileName: string, isOnlyDownload: boolean): void {
    const url = URL.createObjectURL(blob);

    if (blob instanceof Blob && (blob.type === 'application/pdf' || blob.type.indexOf('image') > -1) && !isOnlyDownload) {
      // Here means PDF or Image and should be opened in new tab
      window.open(url, '_blank');
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', originalFileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
