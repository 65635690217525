import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MainHeaderComponent } from '@app/core/components/main-header/main-header.component';
import { NgModule } from '@angular/core';
import { PagenotfoundComponent } from '@app/core/pages/pagenotfound/pagenotfound.component';
import { RouterModule } from '@angular/router';
import { WelcomeScreenComponent } from '@app/core/pages/welcome-screen/welcome-screen.component';

@NgModule({
    declarations: [
        PagenotfoundComponent,
        WelcomeScreenComponent,
        MainHeaderComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        DatePipe
    ],
    exports: [
        MainHeaderComponent
    ]
})
export class CoreModule { }
