import { FormFieldError } from '@app/core/models/form-field-error.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonErrorHandlerService {

    constructor() { }

    getErrors(errors, fieldNames, key: string) {
        const result: FormFieldError[] = [];
        if (key.match(/.\d./)) {
            const keyDataParts = key.split('.');
            key = keyDataParts.splice(2).join('.');
        }

        if (errors !== null) {
            Object.keys(errors).forEach(keyError => {
                let labelValue = fieldNames[key];
                let maxValue;
                let minValue;
                switch (keyError) {
                    case 'requiredNoLabel':
                        labelValue = labelValue || '';
                        result.push({ summary: labelValue, field: '' });
                        break;
                    case 'required':
                        labelValue = labelValue || '';
                        const summaryValue = (labelValue as string).includes('is required') || (labelValue as string).includes('year') ? labelValue : `${labelValue} is required`;
                        result.push({ summary: summaryValue, field: 'Field cannot be blank' });
                        break;
                    case 'maxlength':
                        maxValue = errors.maxlength.requiredLength;
                        result.push({ summary: `The max length for ${labelValue} is ${maxValue}`, field: `Max length is ${maxValue}` });
                        break;
                    case 'invalidDate':
                        result.push({ summary: `Date for ${labelValue} is incorrect`, field: 'Value is incorrect' });
                        break;
                    case 'max':
                        maxValue = errors.max.max;
                        result.push({ summary: `Max value for ${labelValue} is ${maxValue}`, field: `Max value is ${maxValue}` });
                        break;
                    case 'min':
                        minValue = errors.min.min;
                        result.push({ summary: `Min value for ${labelValue} is ${minValue}`, field: `Min value is ${minValue}` });
                        break;
                    case 'isinteger':
                        result.push({ summary: `${labelValue} is not a number`, field: 'Value is not a number' });
                        break;
                    case 'zipcode':
                        result.push({ summary: 'Zip Code format is invalid.', field: 'Zip Code format must be #####, #####-####, or A#A #A#' });
                        break;
                    case 'dateRangeFromGreaterTo':
                        result.push({ summary: 'From Date must be on or before To Date', field: 'From Date must be on or before To Date' });
                        break;
                    case 'pattern':
                        if (labelValue === 'Social Security Number') {
                            result.push({ summary: `${labelValue} format must be ###-##-#### or ###-###-###.`, field: 'SSN is invalid' });
                        } else if (labelValue === 'PIN'){
                            result.push({ summary: `Incorrect ${labelValue} format`, field: 'PIN is invalid' });
                        } else {
                            result.push({ summary: `${labelValue} format is incorrect`, field: 'Incorrect format' });
                        }
                        break;
                    case 'email':
                        result.push({ summary: `${labelValue} is invalid`, field: 'Invalid email format' });
                        break;
                    case 'duplicate':
                        result.push({ summary: `${labelValue} is invalid`, field: `${labelValue} must be unique` });
                        break;
                    default:
                        result.push({ summary: labelValue, field: keyError });
                        break;
                }
            });
        }

        return result;
    }
}
