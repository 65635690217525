import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainHeaderModel } from '@app/core/models/main-header.model';
import { OnlineApplicationService } from '@app/core/services/online-application/online-application.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {
    public headerModel: MainHeaderModel;
    public headerText: string;
    public subHeaderText: string;
    private subscription: Subscription;
    private readonly defaultAppName = 'Encompass™';
    private readonly defaultCompanyName = 'J.J. KELLER & ASSOCIATES, INC.';

    constructor(
        private onlineApplicationService: OnlineApplicationService
    ) { 
        this.subscription = new Subscription();
    }
    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription.add(this.onlineApplicationService.getMainHeaderConfiguration().subscribe(res => {
            if (res && res.companyInfo) {
                this.headerModel = res;
                this.headerText = res?.companyInfo?.companyDisplayName || res?.companyInfo?.appDisplayName;
                this.subHeaderText = '';
            } else {
                this.headerText = this.defaultAppName;
                this.subHeaderText = this.defaultCompanyName;
            }
        }));
    }

}
