import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { CompanyInfoModel } from '@app/core/models/company-info.model';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { OnlineApplicationService } from '@app/core/services/online-application/online-application.service';


export const CompanyInfoResolverFn : ResolveFn<CompanyInfoModel> = (route: ActivatedRouteSnapshot, _: RouterStateSnapshot) => {
    
    const router = inject(Router);
    const onlineApplicationService = inject(OnlineApplicationService);

    const companyNumber = route.paramMap.get('id');
    return onlineApplicationService.getOnlineApplicationConfiguration(companyNumber).pipe(
        catchError(() => {
            router.navigate([]);
            return of(null);
        }));
       
};