export abstract class RegularExpressions {
    public static readonly FirstName = /^[A-Za-z0-9_\'\s\-\,\.]*$/;
    public static readonly LastName = /^[A-Za-z0-9_\'\s\-\,\.]*$/;
    public static readonly MiddleInitial = /^[A-Za-z0-9_\'\s\-\,\.]*$/;
    public static readonly EmployeeCode = /^[A-Za-z0-9\'\s\-]*$/;
    // US 5 digit, or US 5+4 or Canadian A1A 1A1
    public static readonly ZipCode = /^((\d{5}-\d{4})|(\d{5})|([A-Za-z]\d[A-Za-z]\s\d[A-Za-z]\d))$/;
    public static readonly SocialSecurityNumber = /^\d{3}-\d{2}-\d{4}$|\d{3}-\d{3}-\d{3}$/;
    public static readonly Currency = /^\d+(\.\d{1,2})?/;
    public static readonly Text = /[a-zA-Z][a-zA-Z0-9-_. ]/;
    public static readonly Pin = /^\d{6}$/;
}
