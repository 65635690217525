<div class="container">
    <p class="welcome-text"><strong>Welcome, Applicant!</strong></p>
    <p class="info-text">This application must be completed in one session and may not be resumed later,
        so it is important to have all necessary information before proceeding. You will
        need the following information to complete the application:</p>
    <ul class="no-indent">
        <li>3 years residence history</li>
        <li>3-10 years of current or previous employment history, including any gaps in employment
            <ul>
                <li>Full addresses, phone numbers, and employment dates needed for previous employers</li>
            </ul>
        </li>
        <li>3 years of traffic conviction and violation history</li>
        <li>3 years of motor vehicle accident history</li>
        <li>Copy of driver's license</li>
        <li>Use your legal name as it appears on your driver's license</li>
    </ul>
    <button type="button" class="btn btn-secondary btn-position" routerLink="/application" [queryParams]="{companyId: companyInfo?.companyId}">OK, Got it</button>
</div>