<div class="container" >
  <div class="text-center mt-2 mb-2 mt-icon">
    <svg width="20%" class="svg-inline--fa fa-tire-flat fa-w-16 fa-4x" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="tire-flat" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
      <g class="fa-group">
        <path class="fa-secondary" fill="#cfd0d1" d="M189.89 416a145.47 145.47 0 0 1-61.57-61.28L191 334.36a79.77 79.77 0 0 0 41 29.57V416h48v-52.07a79.77 79.77 0 0 0 41-29.57l62.68 20.36A145.47 145.47 0 0 1 322.11 416h77A191.26 191.26 0 0 0 448 288c0-106-86-192-192-192S64 182 64 288a191.26 191.26 0 0 0 48.91 128zM256 320a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm102.87-132.75A143 143 0 0 1 400 288a146.93 146.93 0 0 1-1.52 21.05l-62.55-20.32c0-.25.07-.48.07-.73a79.28 79.28 0 0 0-15.88-47.41zM256 144a143.36 143.36 0 0 1 64.08 15l-38.86 53.51a73.73 73.73 0 0 0-50.44 0L191.92 159A143.36 143.36 0 0 1 256 144zm-102.87 43.25l38.75 53.34A79.28 79.28 0 0 0 176 288c0 .25.07.48.07.72L113.52 309a145.25 145.25 0 0 1-1.52-21 143 143 0 0 1 41.13-100.75z"></path>
        <path class="fa-primary" fill="#878b8c" d="M480 416h-2.61A253.53 253.53 0 0 0 512 288c0-141.38-114.62-256-256-256S0 146.62 0 288a253.53 253.53 0 0 0 34.61 128H32a32 32 0 0 0 0 64h448a32 32 0 0 0 0-64zm-81.3 0H113.3A191 191 0 0 1 64 288c0-105.87 86.13-192 192-192s192 86.13 192 192a191 191 0 0 1-49.3 128zM256 256a32 32 0 1 0 32 32 32 32 0 0 0-32-32z"></path>
      </g>
    </svg>
  </div>
  <div class="text-center">
    <h1 class="mb-4">Page not found</h1>
    <p class="info-text"><strong>Uh oh! We can't seem to find the page you're looking for.</strong></p>
    <p class="mb-4">Double check the URL is correct. Otherwise, contact the company that provided the URL and notify them of the problem.</p>
    <p class="">You can also contact Client Services Support at <strong><a href="tel:18003271342">1-800-327-1342</a></strong> if you need more assistance.</p>
  </div>
</div>
