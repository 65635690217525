import { CompanyInfoResolverFn } from '@app/core/resolvers/company-info.resolver';
import { NgModule } from '@angular/core';
import { PagenotfoundComponent } from '@app/core/pages/pagenotfound/pagenotfound.component';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeScreenComponent } from '@app/core/pages/welcome-screen/welcome-screen.component';

const routes: Routes = [
    { path: 'application', loadChildren: () => import('@app/feature-modules/feature-modules.module').then(m => m.FeatureModulesModule) },
    { path: ':id', component: WelcomeScreenComponent, resolve: { companyInfo: CompanyInfoResolverFn } },
    { path: '**', component: PagenotfoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
