import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from '@app/feature-modules/components/base-form.component';
import { CommonErrorHandlerService } from '@app/core/services/common-error-handler.service';
import { CompanyInfoModel } from '@app/core/models/company-info.model';
import { Component, OnInit } from '@angular/core';
import { OnlineApplicationService } from '@app/core/services/online-application/online-application.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RegularExpressions } from '@app/feature-modules/models/regular-expressions.model';
import { OnlineApplicationInformationModel } from '@app/core/models/online-application-information.model';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-welcome-screen',
    templateUrl: './welcome-screen.component.html',
    styleUrls: ['./welcome-screen.component.scss']
})
export class WelcomeScreenComponent extends BaseFormComponent implements OnInit {
    public companyInfo: CompanyInfoModel;
    private onlineApplicationInfo: OnlineApplicationInformationModel;
    public sectionName = 'welcomePage';
    public summaryErrors: any;
    public showSummaryErrors: boolean;

    readonly fieldNames = {
        userName: 'Username',
        pin: 'PIN'
    };

    constructor(
        private fb: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        protected onlineApplicationService: OnlineApplicationService,
        protected commonErrorHandler: CommonErrorHandlerService,
        private router: Router
    ) {
        super(commonErrorHandler, onlineApplicationService);
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.companyInfo = this.activatedRoute.snapshot.data.companyInfo;
        this.initForm();
        this.hideErrors();
    }

    ngOnDestroy(): void {
        this.subscriptions?.unsubscribe();
    }

    handleResume() {
        this.showErrors(this.getFormGroupValidationErrors());
        if (this.form.valid) {
            this.hideErrors();
            const companyId = this.onlineApplicationService.onlineApplicationConfiguration.value.companyId;
            const userName = this.form.get('userName')?.value;
            const pin = this.form.get('pin')?.value;
            this.subscriptions.add(this.onlineApplicationService.getOnlineApplication(companyId, userName, pin                
            ).subscribe({
                next: res => {
                    if (res?.applicantData) {
                        this.onlineApplicationInfo = JSON.parse(res.applicantData);
                        // Load the data from db to the service 
                        Object.entries(this.onlineApplicationInfo).forEach(([key, value]) => {
                            this.onlineApplicationService.setPageData(key, value);
                        });
                        // set the eTag and id to the service for future updates
                        this.onlineApplicationService.setPageData('eTag', res?.eTag);
                        this.onlineApplicationService.setPageData('id', res?.id);
                        this.router.navigate(['/application'], { queryParams: { companyId: companyId } });
                    }
                },
                error: error => {
                    this.handleError(error);
                }
            }));
        }
    }

    clearForm() {
        this.fieldErrors.userName = [];
        this.fieldErrors.pin = [];
        this.hideErrors();
    }

    private initForm() {
        this.form = this.fb.group({            
            userName: ['', [Validators.required, Validators.maxLength(15)]],
            pin: ['', [Validators.required, Validators.pattern(RegularExpressions.Pin)]]
        });
    }

    private handleError(errorResponse: HttpErrorResponse) {
        this.summaryErrors = [];
        if (errorResponse.error?.errors) {
          Object.values(errorResponse.error.errors).forEach((error: string[]) => {
            error.forEach(er => {
              this.summaryErrors.push(er);
            });
          });
        } else if (errorResponse.message) {
          this.summaryErrors.push(errorResponse.message);
        } else if (errorResponse.error) {
          if (errorResponse.error.text) {
            this.summaryErrors.push(errorResponse.error.text);
          } else {
            this.summaryErrors.push(errorResponse.error);
          }
        } else if (errorResponse.statusText) {
          this.summaryErrors.push(errorResponse.statusText);
        }
    
        this.showSummaryErrors = true;
      }

      private showErrors(errors) {
        if(errors?.length > 0 ){
            this.showSummaryErrors = true;
            this.summaryErrors = errors;
        }
      }
    
      private hideErrors() {
        this.showSummaryErrors = false;
        this.summaryErrors = [];
      }
}