import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CompanyInfoModel } from '@app/core/models/company-info.model';

@Component({
    selector: 'app-welcome-screen',
    templateUrl: './welcome-screen.component.html',
    styleUrls: ['./welcome-screen.component.scss']
})
export class WelcomeScreenComponent implements OnInit {
    public companyInfo: CompanyInfoModel;

    constructor(
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.companyInfo = this.activatedRoute.snapshot.data.companyInfo;
    }
}